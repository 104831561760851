function is_touch_device() {
    return (('ontouchstart' in window)
    || (navigator.MaxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0));
}

$("#menu-o").click(function () {
    $("#menu-a").fadeIn(400);
});

$("#menu-cl").click(function () {
    $("#menu-a").fadeOut(300);
});

/* IMAGES */
if ($('.svgInject').length > 0) {

    $(".svgInject").svgInject();
}

$(".faq_q").click(function () {

    var faq = $(this).closest(".faq");
    if (faq.find(".faq_a").hasClass("sa")) {
    } else {
        $(".faq").not(faq).find(".faq_a").slideUp(600);
        faq.find(".faq_a").slideToggle(600);
    }

});

$(".osub").on('mouseenter', function () {
    $(this).find(".sub").stop().fadeIn(120);
}).on('mouseleave', function () {
        $(this).find(".sub").stop().fadeOut(120);
    }
);



/* image fills its parent <div class="filling-image" style="width:300px; height:200px;"><img src="Woody.jpg" /></div> */

if ($('.filling-image').length > 0) {
    $(".filling-image").imgLiquid();

}
/* placeholder in IE8 */

if ($('.form-placeholder').length > 0) {
    $('[placeholder]').focus(function () {
        var input = $(this);
        if (input.val() == input.attr('placeholder')) {
            input.val('');
            input.removeClass('placeholder');
        }
    }).blur(function () {
        var input = $(this);
        if (input.val() == '' || input.val() == input.attr('placeholder')) {
            input.addClass('placeholder');
            input.val(input.attr('placeholder'));
        }
    }).blur();
}

/* menu item highlighted per pagina */

if ($('.page-id').length > 0) {
    pagina = $('.page-id').attr('id');
    $('.menu-a').removeClass('menu-a-active');
    $('.menu-' + pagina).addClass('menu-a-active');
}


$('body,html').bind('scroll mousedown wheel DOMMouseScroll mousewheel keyup', function (e) {
    if (e.which > 0 || e.type == "mousedown" || e.type == "mousewheel") {
        $("html,body").stop();
    }
});


/* TEXT */

/* max amount of chars in text */

if ($('.limit-text').length > 0) {
    $('.limit-text').succinct({
        size: 120
    });
}

/* make text-size responsive to width */

if ($('.squishy').length > 0) {
    $(".squishy").squishy({maxWidth: 200, minSize: 14});
}


/* scroll div */
if ($('.slimscroll').length > 0) {
    $.getScript("/js/scripts/jquery.slimscroll.min.js", function () {
        $('.slimscroll').slimScroll({
            position: 'right',
            color: '#ffcc00',
            alwaysVisible: true,
            height: '100%',
            railColor: '#222',
            railOpacity: 0.3
        });
    });
}

$('.scrollto').click(function () {
    var el = $(this).attr("data-scrollto");
    if (el == 'next') {
        $('body, html').animate({
            scrollTop: $(this).closest(".part").next().offset().top - 90
        }, 1200);
    } else {
        $('body, html').animate({
            scrollTop: $("#" + el).offset().top - 90
        }, 1200);
    }
});


if($('#student').is(':checked')) {
    $('.studentCard').fadeIn(500).show();
}

$('#student').on('change' , function()
{
    if($(this).is(':checked') == true) {
        $('.studentCard').fadeIn(500).show();
    } else {
        $('.studentCard').fadeOut(500).hide();
    }
});
